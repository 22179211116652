import axios from 'axios';
import generateSignature from './signatureService';

const baseUrl = process.env.VUE_APP_API_URL;
const apiKey = process.env.VUE_APP_API_KEY;
const secretKey = process.env.VUE_APP_SECRET_KEY;

const timeHttp = axios.create({ baseURL: baseUrl });
const axiosInstance = axios.create({ baseURL: baseUrl });

axiosInstance.interceptors.request.use(
    async config => {
        try {
            const res = await timeHttp.get('/timestamp');
            const timeStamp = res.data.timeStamp;

            const token = localStorage.getItem('token');
            const key = generateSignature(apiKey, timeStamp, secretKey);
            
            config.headers = {
                'Content-Type': 'application/json',
                'Api-Key': apiKey,
                Signature: key, 
                Authorization: 'Bearer ' + token,
                TimeStamp: timeStamp
            };
        } catch (error) {
            console.log(error);
        }               
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

export default axiosInstance;