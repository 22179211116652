<template>
    <section class="main-content-section">
        <div class="grid">
            <LoadingSpinner v-if="isLoading"/>
            
            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="form-container authentication">
                    
                    <h1 class="page-title">{{login}}</h1>
                    <!-- <div class="info">
                        {{pleaseLoginToViewOrderDetails}}
                        <router-link to="/product-catalog">{{clickHere}}</router-link>
                    </div> -->
                    
                    <form @submit.prevent="submitForm()">
                        <div class="form-row flex flex-column">
                            <label for="email">{{emailAddress}} *</label>
                            <input 
                                id="email" 
                                type="email"
                                v-model.trim="email"
                                @input="$v.email.$touch()"
                                :class="{ error: $v.email.$error }" 
                            />
                            <div v-if="$v.email.$dirty">
                                <p class="error" v-if="!$v.email.email">{{validEmailAddress}}</p>
                                <p class="error" v-if="!$v.email.required">{{emailAddress}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row flex flex-column">
                            <label for="password">{{passwordLabel}} *</label>
                            <input 
                                id="password"
                                type="password"
                                autocomplete="off" 
                                v-model.trim="password"
                                @input="$v.password.$touch()"
                                :class ="{ error: $v.password.$error }"
                            />
                            <div v-if="$v.password.$dirty">
                                <p class="error" v-if="!$v.password.required">{{passwordLabel}} {{isRequired}}</p>
                            </div>
                        </div>

                        <div class="form-row">
                            <router-link to="/forgot-your-password">{{forgotMyPassword}}</router-link>
                        </div>

                        <div class="form-row">
                            <span class="small">* {{indicatesRequiredField}}</span>
                        </div>

                        <div class="form-row flex justify-center">
                            <button type="submit" class="btn btn-primary alternative submitBtn">{{login}}</button>
                        </div>                      
                    </form>
                </div>
            </div>
        </div>
        <WarningMessageModal v-show="showWarningModal" :title="errorTitle" :text="errorMessage" @closeModal="closeModal()"/>
    </section>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import WarningMessageModal from '@/components/modals/WarningMessageModal';
import agent from '../../api/agent';
import { mapGetters } from 'vuex';
import LoadingSpinner from '../../components/ui/LoadingSpinner.vue'; 

export default {
    name: 'LoginPage',
    components: { WarningMessageModal, LoadingSpinner },
    
    data() {
        return {
            isLoading: false,
            email: '',
            password: '',
            errorTitle: '',
            errorMessage: '',
            showWarningModal: false,
        }
    },

    validations: {
        email: { required, email },
        password: { required },
    },

    created() {
       this.setDocumentTitle();
    },

    updated() {
        this.setDocumentTitle();
    },

    computed: {
        ...mapGetters([
            'isAuthenticated',
            'metaTitle',
            // Texts 
            'isRequired', 
            'indicatesRequiredField', 
            'notAllRequiredFieldsAreCompleted', 
            'emailAddress', 
            'validEmailAddress', 
            'incorrectPasswordConfirmation', 
            'passwordMustBeStronger', 
            'forgotMyPassword', 
            'pleaseLoginToViewOrderDetails', 
            'clickHere', 
            'login',
        ]),
        ...mapGetters({
            passwordLabel: 'password'
        })
    },

    methods: {
        setDocumentTitle() {
            document.title = this.login + this.metaTitle;
        },

        showModal() {
            this.showWarningModal = true;
        },

        closeModal() {
            this.showWarningModal = false;
        },

        parseJwt(token) {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        },

        async portalLogin(recaptchaToken) {
            try {
                this.isLoading = true;

                const { token } = await agent.users.login({
                    email: this.email,
                    password: this.password,
                    token: recaptchaToken
                });
                
                localStorage.setItem('token', token);
                const { userId, orderId, role, storeId } = this.parseJwt(token);
    
                this.$store.commit('SET_ORDER_ID', orderId);
                this.$store.commit('SET_USER_ID', userId);
                this.$store.commit('SET_ROLE', role);
                this.$store.commit('SET_STORE_ID', storeId);
    
                this.$store.commit('SET_AUTHENTICATED', true);
                this.$store.commit('SET_TOKEN_IS_EXPIRED', false);

                this.isLoading = false;

                if (token && role === 'Report') {
                    this.$router.push('/report/status');
                } else if (token && role === 'Store') {
                    this.$router.push('/product-catalog');
                } else if (token && role === 'Service') {
                    this.$router.push('/service/search-order');
                }
            } catch (error) {
                this.isLoading = false;
                console.log(error.response.data);
                this.errorTitle = error.response.data.title;
                this.errorMessage = error.response.data.detail;
                this.showModal();
            }
        },

        async getRecaptchaToken() {
            this.isLoading = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            this.portalLogin(token);
        },

        submitForm() {
            if (this.$v.email.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.emailAddress + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if (this.$v.password.$invalid) {
                this.errorTitle = this.notAllRequiredFieldsAreCompleted;
                this.errorMessage = this.passwordLabel + ' ' + this.isRequired;
                this.showModal();
                return;
            }

            if(!this.$v.$invalid) {
                this.getRecaptchaToken();
            }
        }
    }
}
</script>