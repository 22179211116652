import languages from './endpoints/languages';
import products from './endpoints/products';
import order from './endpoints/order';
import stores from './endpoints/stores';
import users from './endpoints/users';
import pages from './endpoints/pages';
import texts from './endpoints/texts';
import settings from './endpoints/settings';
import email from './endpoints/email';
import validations from './endpoints/validations';
import report from './endpoints/report';
import service from './endpoints/service';

const agent = {
    languages,
    products,
    order,
    stores,
    users,
    pages,
    texts,
    settings,
    email,
    validations,
    report,
    service,
};

export default agent;