import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const textsModule = {
    state() {
        return {
            homeText: null,
            texts: null,
        };
    },
    mutations: mutations,
    actions: actions,
    getters: getters
};