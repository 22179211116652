import agent from '../../api/agent';

export default {
    fetchLanguages: async ({commit}) => {
        try {
            const languages = await agent.languages.all();
            if (languages.length) {
                commit('SET_LANGUAGES', languages);
                commit('SET_SELECTED_LANGUAGE', languages[0].number);
            }
        } catch (error) {
            console.log(error);
        }
    },

    selectLanguage: ({commit}, languageId) => {
        commit('SET_SELECTED_LANGUAGE', languageId);
    }
}