import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export const accountModule = {
    state() {
        return {
            userId: null,
            role: '',
            storeId: null,
            token: null,
            authenticated: false,
            tokenIsExpired: true,
            userData: {
                firstName: '',
                lastName: '',
                loyaltyCardNumber: '',
                digitalCollector: false,
                email: '',
                emailConfirmation: '',
                password: '',
                passwordConfirmation: '',
                phone: '',
                mobile: '',
                terms: false,
                privacyPolicy: false,
            },
            authErrorsTitle: null,
            authErrorsDescription: null,
        };
    },
    mutations: mutations,
    actions: actions,
    getters: getters
};