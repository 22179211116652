import requests from "../requests";
import { documentDownloadHeadersConfig, pdfDownloadHeadersConfig } from '../../utils/downloadService';

const report = {
    status: () => requests.get('/report/status'),
    storeOverview: () => requests.get('/report/stores', documentDownloadHeadersConfig),
    orderList: () => requests.get('/report/orderlist', documentDownloadHeadersConfig),
    orderDump: () => requests.get('/report/orderdump', documentDownloadHeadersConfig),
    orderDumpPerStore: () => requests.get('/report/orderdumpperstore', documentDownloadHeadersConfig),
    ordersPerStore: () => requests.get('/report/orderlistperstore', documentDownloadHeadersConfig),
    baseline: () => requests.get('/report/baseline', documentDownloadHeadersConfig),
    orderListForStore: () => requests.get('/report/orderlistforstore', pdfDownloadHeadersConfig),
};

export default report;