import requests from "../requests";

const users = {
    login: credentials => requests.post('/users/portal/login', credentials),
    register: user => requests.post('/users/register', user),
    forgotPassword: email => requests.post('/users/portal/forgotpassword', email),
    resetPassword: credentials => requests.post('/users/portal/resetpassword', credentials),
    changePassword: credentials => requests.post('/users/changepassword', credentials),
    userDetails: id => requests.get(`/users/${id}`),
    updateUser: user => requests.put('/users/updateuser', user),
    removeUser: () => requests.del('/users/removeuser')  
};

export default users;